import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useAppSelector } from '../state/hooks';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

const Modal = dynamic(() => import('@internal/common/base/components/FormModal'), { ssr: false });

const ErrorFallback: React.FunctionComponent<FallbackProps> = (props) => {
    const router = useRouter();
    const recorder = !!router.query.recorder;

    useEffect(() => {
        if (recorder) {
            window.location.reload();
        }
    }, [recorder]);

    if (recorder) {
        return null;
    }

    return (
        <Modal
            visible
            mobile={useAppSelector((state) => state.app.mobile)}
            format="negative"
            dismissOnBackground={false}
            title="Oops, looks like something went wrong!"
            subtitle="The bug has been reported — we’ll fix it ASAP. Please reload the page to continue."
            ctaText="Reload"
            onAccept={() => {
                window.location.reload();
            }}
        />
    );
};

export default ErrorFallback;
