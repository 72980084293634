import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { localStorageSet, localStorageDelete, LocalStorageKey } from 'constants/localStorage';
import {
    setChatPane,
    toggleMessageList,
    toggleMessageNotifications,
    updatePinnedHideTime,
} from '@internal/common/state/messages';
import { closeSupportChat, openSupportChat, setRooms } from '../app';
import { AppState } from '../store';
import { ChannelType } from '@internal/common/text-chat/types';

const localStorage =
    (store: MiddlewareAPI<Dispatch<AnyAction>, AppState>) =>
    (next: Dispatch<AnyAction>) =>
    async (action: AnyAction) => {
        switch (action.type) {
            case setChatPane.toString(): {
                const room = store.getState().room;
                const key = `${LocalStorageKey.MessagesPane}_${room.urlName}`;
                if (action.payload === ChannelType.Room) {
                    localStorageDelete(key);
                } else {
                    localStorageSet(key, action.payload);
                }
                break;
            }
            case openSupportChat.toString(): {
                const room = store.getState().room;
                localStorageSet(`${LocalStorageKey.SupportEnabled}_${room.urlName}`, true);
                break;
            }
            case closeSupportChat.toString(): {
                const room = store.getState().room;
                localStorageDelete(`${LocalStorageKey.SupportEnabled}_${room.urlName}`);
                break;
            }
            case setRooms.toString():
                localStorageSet(LocalStorageKey.RoomCount, action.payload.length);
                break;
            case toggleMessageNotifications.toString():
                localStorageSet(
                    LocalStorageKey.ChatNotificationsDisabled,
                    store.getState().messages.notificationsEnabled
                );
                break;
            case updatePinnedHideTime.toString():
                if (action.payload.time) {
                    localStorageSet(LocalStorageKey.PinnedMessages, Date.now());
                } else {
                    localStorageDelete(LocalStorageKey.PinnedMessages);
                }
                break;
            case toggleMessageList.toString(): {
                if (typeof action.payload === 'undefined') {
                    const open = store.getState().messages.open;
                    localStorageSet(LocalStorageKey.MessagesOpen, !open);
                } else {
                    localStorageSet(LocalStorageKey.MessagesOpen, action.payload);
                }
                break;
            }
            default:
        }

        return next(action);
    };

export default localStorage;
