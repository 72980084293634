import {
    onOpen,
    onDisconnect,
    onRoomState,
    onTrack,
    onRemoteParticipantUnregister,
    onRemoteParticipantUpdate,
    onContentState,
    onContentStreamMetadata,
    onVoteStreakState,
    onVoteState,
    onParticipantMute,
    onRoomMutedUpdate,
    onRoomSettingsUpdate,
    onStageInvite,
    onStageRequest,
    onStageAccept,
    onStageUpdate,
    onStageKick,
    onStageRSVP,
    onQualityIndicator,
    onSubsQueueSizeUpdate,
    onRemoteParticipantsUpdate,
} from '@internal/common/state/participant';
import { Middleware } from 'redux';
import { addStream } from '@internal/common/state/content';
import { AppState } from 'state/store';
import {
    setRoom,
    updateMuted,
    updateSettings,
    updateStage,
    setConnected,
    setRoomState,
    setSubsQueueSize,
    updateContentState,
    updateContentStreamMetadata,
    updateVoteState,
    updateVoteStreakState,
    updateQualityIndicator,
    updateParticipant,
    updateParticipants,
    removeParticipant,
} from '@internal/common/state/room';
import {
    stageInviteRevoked,
    stageRequestAccepted,
    stageRequestDenied,
    leftStage,
} from '@internal/common/state/chat';
import { showModal, showNotification, track } from '../app';
import { ModalType } from '../../typescript/typings';
import { roomGET } from '@internal/api/room';
import { API_HOST } from '../../typescript/api';
import { NotificationType } from '@internal/common/base/types';
import { PermissionType } from '@internal/api/types';
import { SignalVoteStreakState } from '@internal/room-client/common';

export const roomParticipant: Middleware<{}, AppState> = (store) => (next) => async (action) => {
    switch (action.type) {
        case onOpen.toString():
            store.dispatch(setConnected(true));
            break;
        case onDisconnect.toString():
            store.dispatch(setConnected(false));
            store.dispatch(showModal({ type: ModalType.RoomDisconnected }));
            break;
        case onRoomState.toString(): {
            const payload = (action as ReturnType<typeof onRoomState>).payload;

            store.dispatch(setRoomState(action.payload));
            store.dispatch(updateStage(payload.stage));
            break;
        }
        case onTrack.toString():
            store.dispatch(addStream(action.payload));
            break;
        case onRemoteParticipantUnregister.toString(): {
            store.dispatch(removeParticipant(action.payload.participantID));
            break;
        }
        case onRemoteParticipantUpdate.toString(): {
            store.dispatch(updateParticipant(action.payload.participant));
            break;
        }
        case onRemoteParticipantsUpdate.toString(): {
            store.dispatch(updateParticipants(action.payload.participants));
            break;
        }
        case onContentState.toString():
            store.dispatch(updateContentState(action.payload.contentState));
            break;
        case onContentStreamMetadata.toString():
            store.dispatch(updateContentStreamMetadata(action.payload));
            break;
        case onVoteStreakState.toString(): {
            const { participant, winner } = <SignalVoteStreakState>action.payload;
            if (participant?.id === store.getState().room.participantID) {
                store.dispatch(track({ event: 'Vote Streak Started', winner }));
            }

            store.dispatch(updateVoteStreakState(action.payload));
            break;
        }
        case onVoteState.toString():
            store.dispatch(updateVoteState(action.payload));
            break;
        case onParticipantMute.toString(): {
            break;
        }
        case onRoomMutedUpdate.toString(): {
            const payload = (action as ReturnType<typeof onRoomMutedUpdate>).payload;
            const userID = store.getState().user.id;
            const prevMuted = store.getState().room.muted;

            if (!(userID in prevMuted) && userID in payload.muted) {
                store.dispatch(showNotification({ type: NotificationType.MicMuted }));
            }

            store.dispatch(updateMuted(action.payload.muted));
            break;
        }
        case onSubsQueueSizeUpdate.toString(): {
            store.dispatch(setSubsQueueSize(action.payload.size));
            break;
        }
        case onRoomSettingsUpdate.toString(): {
            const id = store.getState().room.id;
            store.dispatch(updateSettings(action.payload.settings));
            store.dispatch(setRoom(await roomGET({ id }, API_HOST)));
            break;
        }
        case onStageInvite.toString(): {
            const payload = (action as ReturnType<typeof onStageInvite>).payload;
            store.dispatch(stageInviteRevoked());
            const stage = !store.getState().room.settings.openSpeak;
            const isMod = store
                .getState()
                .room.participant?.permissions?.find(
                    (p) => p.permission === PermissionType.StageInvite && p.enabled
                );

            if (stage && !isMod && !payload.revoke) {
                store.dispatch(showNotification({ type: NotificationType.StageInvite }));
            }

            break;
        }
        case onStageRequest.toString():
            break;
        case onStageAccept.toString(): {
            store.dispatch(action.payload.accepted ? stageRequestAccepted() : stageRequestDenied());
            const stage = !store.getState().room.settings.openSpeak;
            const isMod = store
                .getState()
                .room.participant?.permissions?.find(
                    (p) => p.permission === PermissionType.StageInvite && p.enabled
                );

            if (stage && !isMod && action.payload.accepted) {
                store.dispatch(showNotification({ type: NotificationType.StageInvite }));
            }

            break;
        }
        case onStageUpdate.toString(): {
            const payload = (action as ReturnType<typeof onStageUpdate>).payload;
            const userID = store.getState().user.id;
            const prevStage = store.getState().room.stage.members;
            store.dispatch(updateStage(payload.stage));

            if (userID in prevStage && !(userID in payload.stage.members)) {
                store.dispatch(leftStage());
            }

            break;
        }
        case onStageKick.toString():
            break;
        case onStageRSVP.toString():
            break;
        case onQualityIndicator.toString():
            const payload = (action as ReturnType<typeof onQualityIndicator>).payload;
            store.dispatch(updateQualityIndicator(payload));
            break;
        default:
    }

    return next(action);
};

export default roomParticipant;
